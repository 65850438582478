import React from "react"

const ArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="5"
    height="6"
    viewBox="0 0 5 6">
    <path
      fillRule="nonzero"
      d="M.783.088A.5.5 0 0 0 0 .5v5a.5.5 0 0 0 .783.412l4-2.5a.5.5 0 0 0 0-.824l-4-2.5z"></path>
  </svg>
)

export default ArrowSvg
