import React, { useState } from "react"

export const SelectedMenuContext = React.createContext({
  selectedMenu: "",
  setSelectedMenu: () => {},
})

export const SelectedMenuContextProvider = props => {
  const setSelectedMenu = menu => {
    setState({ ...state, selectedMenu: menu })
  }

  const initialState = {
    selectedMenu: null, // No menu is selected when the website opens
    setSelectedMenu: setSelectedMenu,
  }

  const [state, setState] = useState(initialState)

  return (
    <SelectedMenuContext.Provider value={state}>
      {props.children}
    </SelectedMenuContext.Provider>
  )
}
