import React, { useEffect, useState } from "react"
import styles from "./cookie-consent.module.scss"
import ArrowSvg from "./../svg/arrow"
import Cookies from "js-cookie"
import { Helper } from "../../utils/helper"
import { PRIVACY_POLICY_PAGE_URL } from "../../utils/constants"

const USER_OPT_IN = "_opt"
let OPT_IN_SETUP = false

const CookieConsent = () => {
  const [moreInfoVisible, setMoreInfoVisible] = useState(false)
  const [consentVisible, setConsentVisible] = useState(null)

  useEffect(() => {
    if (!Helper.isBrowser()) {
      return
    }

    const userOpt = Cookies.get(USER_OPT_IN)

    if (!userOpt) {
      setConsentVisible(true)
      return
    }

    if (!OPT_IN_SETUP) {
      OPT_IN_SETUP = true
      window.dataLayer = window.dataLayer || []
      if (userOpt === "true") {
        Helper.userAccepted(true)
      }

      if (userOpt === "false") {
        Helper.userAccepted(false)
      }
    }
  }, [consentVisible])

  const setMoreInfo = event => {
    event.preventDefault()
    setMoreInfoVisible(!moreInfoVisible)
  }

  const acceptCookies = function() {
    Cookies.set(USER_OPT_IN, true, {
      expires: 30,
    })
    setConsentVisible(false)
  }

  const declineCookies = function() {
    Cookies.set(USER_OPT_IN, "false")
    setConsentVisible(false)
  }

  return (
    <div
      className={`${styles.consent}
                         ${consentVisible ? "" : styles.consentHidden}`}>
      <div className={styles.consent__inner}>
        <h4>This website stores cookies on your computer</h4>
        <p>
          These cookies are used to collect information about how you interact
          with our website and allow us to remember you. We use this information
          to improve and customize your browsing experience and for analytics
          and metrics about our visitors both on this website and other media.
          To find out more about the cookies we use, see the{" "}
          <a href={PRIVACY_POLICY_PAGE_URL} target="_blank">
            privacy policy
          </a>
          .
        </p>
        <p>
          If you reject, your information won’t be tracked when you visit this
          website. A single cookie will be used in your browser to remember your
          preference not to be tracked.
        </p>
        <div className={styles.consent__more}>
          <a
            href="#"
            className={`${styles.consent__morebtn}
                                ${
                                  moreInfoVisible
                                    ? styles.consent__morebtnActive
                                    : ""
                                }`}
            onClick={event => setMoreInfo(event)}>
            Learn More
            <ArrowSvg />
          </a>
          <div
            className={`${styles.consent__desc} 
                                ${
                                  moreInfoVisible
                                    ? styles.consent__descShow
                                    : null
                                }`}>
            <h6>Basic interactions & functionalities</h6>
            <p>
              These cookies will allow us to better understand how you’re using
              our website, evaluate and improve our performance and ultimately
              provide a better experience.
            </p>
            <h6>Advertisement Cookies</h6>
            <p>
              These cookies will allow us to evaluate your preferences, build
              your interest profile and help you see ads that are more useful,
              and more relevant to your interests.
            </p>
          </div>
        </div>
        <div className={styles.consent__cta}>
          <button
            className={`btn ${styles.btnOutline}`}
            onClick={() => {
              acceptCookies()
            }}>
            Accept All Cookies
          </button>
          <button
            className={`btn ${styles.btnOutline}`}
            onClick={() => {
              declineCookies()
            }}>
            Reject Cookies
          </button>
        </div>
      </div>
    </div>
  )
}

export default CookieConsent
