import React, { useState } from "react"
import { Helper } from "../utils/helper"

export const ShowAnnouncementContext = React.createContext({
  showAnnouncement: null,
  setShowAnnouncement: () => {},
})

export const ShowAnnouncementContextProvider = props => {
  const setShowAnnouncement = menu => {
    setState({ ...state, showAnnouncement: menu })
  }

  const initialState = {
    // It is very important to have real default value
    // else the announcement bar won't be returned in HTML!
    // But actually it will be dynamically added via javascript.
    showAnnouncement: Helper.shouldShowAnnouncement(),
    setShowAnnouncement: setShowAnnouncement,
  }

  const [state, setState] = useState(initialState)

  return (
    <ShowAnnouncementContext.Provider value={state}>
      {props.children}
    </ShowAnnouncementContext.Provider>
  )
}
