import React from "react"

const TwitterSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="15"
    viewBox="0 0 18 15">
    <path
      fillRule="evenodd"
      d="M18 1.688c-.675.337-1.35.45-2.138.562.788-.45 1.35-1.125 1.575-2.025-.674.45-1.462.675-2.362.9A3.928 3.928 0 0 0 12.375 0c-1.913 0-3.6 1.688-3.6 3.713 0 .337 0 .562.113.787-3.038-.112-5.85-1.575-7.65-3.825C.9 1.237.787 1.8.787 2.588c0 1.237.674 2.362 1.687 3.037-.562 0-1.125-.225-1.687-.45 0 1.8 1.237 3.263 2.924 3.6-.337.112-.674.112-1.012.112-.225 0-.45 0-.675-.112.45 1.463 1.8 2.588 3.488 2.588C4.275 12.375 2.7 12.938.9 12.938H0c1.688 1.012 3.6 1.687 5.625 1.687 6.75 0 10.463-5.625 10.463-10.463v-.45C16.875 3.15 17.55 2.476 18 1.688"
    />
  </svg>
)

export default TwitterSvg
