import React from "react"

const CrossSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M13.7.3c-.4-.4-1-.4-1.4 0L7 5.6 1.7.3C1.3-.1.7-.1.3.3c-.4.4-.4 1 0 1.4L5.6 7 .3 12.3c-.4.4-.4 1 0 1.4.2.201.4.3.7.3.3 0 .5-.099.7-.3L7 8.401l5.3 5.299c.2.201.5.3.7.3.2 0 .5-.099.7-.3.4-.4.4-1 0-1.4L8.4 7l5.3-5.3c.4-.4.4-1 0-1.4"
    />
  </svg>
)

export default CrossSvg
