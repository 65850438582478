import React, { useState } from "react"

export const LanguageContext = React.createContext({
  language: "",
  setLanguage: () => {},
})

export const LanguageContextProvider = props => {
  const setLanguage = language => {
    setState({ ...state, language })
  }

  const getUserLanguage = () => {
    if (
      typeof window !== "undefined" &&
      window.Localize &&
      window.Localize.getLanguage()
    ) {
      return window.Localize.getLanguage()
    }

    if (
      typeof localStorage !== "undefined" &&
      localStorage.getItem("ljs-lang")
    ) {
      return localStorage.getItem("ljs-lang")
    }

    return "en"
  }

  const initialState = {
    language: getUserLanguage(),
    setLanguage,
  }

  const [state, setState] = useState(initialState)

  return (
    <LanguageContext.Provider value={state}>
      {props.children}
    </LanguageContext.Provider>
  )
}
